@import "~bootstrap/scss/bootstrap";

body{
    margin: 0;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
}

.hand-banner {
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 40%;
}

h1 {
    font-weight: 900;
    color: #15385b;
    letter-spacing: .05em;
    margin-bottom: 0.5rem;
}

.border-separator {
    background-color: #f0b93b;
    display: block;
    height: 5px;
    width: 100px;
    margin: 25px 0;
}

#left-div {
    resize: horizontal !important;
    max-width: 100%;
}

.modal-content {
    background: rgba(107, 107, 107, 0.007);
    position: fixed;
    height: auto;
    width: 80%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: none;
}


  