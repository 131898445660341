.autoLogout {
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
  padding: 20px;
  background-color: #0E2942;
  color: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  text-align: center;
  font-size: 18px;
}

.buttons {
  margin-top: 30px;
}
