.masthead {
    position: relative;
    margin-top: 4.6rem;
    padding: 0!important;
}

.auth {
    background: url(../img/reg-images/reg-header.png) no-repeat 100%!important;
    background-size: 110% auto!important;
    padding-top: 6rem!important;
}

.text-white {
    color: #fff !important;
}

.text-right {
    text-align: right;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    font-size: .875rem !important;
    line-height: 1.4rem;
    vertical-align: top;
    margin-bottom: 0.5rem;
}

.form-control {
    border: 1px solid #f3f3f3;
    font-size: .875rem !important;
    font-weight: 400;
    display: block;
    width: 100%;
    height: 2.875rem;
    padding: 0.875rem 1.375rem;
    line-height: 1;
    color: gray;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 2px;
    -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
}

.form-control::placeholder {
    color: rgb(183, 179, 189) !important;
}

.serach {
    margin-right: 4px !important;
    border: none !important;
}

input, .input-group-text {
    background-color: #fff;
    border: none !important;
}

input:hover {
    border: 1px solid gray !important;
}

.footer {
    background: url(../img/reg-images/reg-footer.png) no-repeat 100% 100% !important;
    font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
    background: #f3f3f3;
    padding: 30px 1rem;
    transition: all .25s ease;
    -moz-transition: all .25s ease;
    -webkit-transition: all .25s ease;
    -ms-transition: all .25s ease;
    font-size: .825rem;
    font-weight: 400;
    border-top: 1px solid rgba(0,0,0,.06);
}


