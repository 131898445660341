.projectSelector {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  /* Styles the scrollbar itself (thumb) */
  ::-webkit-scrollbar-thumb {
    background-color: #0e2942;  /* Thumb color */
    border-radius: 10px;         /* Round corners of the scrollbar thumb */
  }

  /* Styles the part of the scrollbar track */
  ::-webkit-scrollbar-track {
    background: #081624;        /* Track color */
    border-radius: 10px;         /* Round corners of the scrollbar track */
  }

  /* Adjust the width of the scrollbar */
  ::-webkit-scrollbar {
    width: 8px;                 /* Width of the vertical scrollbar */
  }

  
}

.projectSelector::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-image: url("../../assets/img/edai-logo-small.png");
  background-repeat: no-repeat;
  background-position: left top;
  z-index: -1;
  margin: 10px;
}

.projectSelector::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background-image: url("../../assets/img/banner-hand.png");
  background-repeat: no-repeat;
  background-position: right bottom;
  z-index: -1;
}

.card {
  display: flex;
  justify-items: center;
  width: 600px; /* Adjust this value based on your layout */
  max-height: 600px;
  text-align: start;
  background-color: #081624 !important;
  opacity: 0.9;
  color: #fcfbfb;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  overflow-y: auto !important; 
}


.projectSelectorTitle {
  margin-top: 10px;
  margin-bottom: 20px !important;
  font-size: large;
  font-weight: bold;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  color: #fcfbfb;
}

.projectListItem {
  cursor: pointer;
  background-color: #081624 !important;
  transition: background-color 0.3s ease;
  border: 0.5px solid #14304c !important;

  &:hover {
    background-color: #0e2942 !important;
    border-bottom: 0.5px solid #f0b93b !important;
  }

  .itemTitle {
    font-size: medium;
    font-weight: normal;
    margin-bottom: 0px !important;
  }



.customModal {
  background-color: #0e2942 !important; 
}
}
