.diagnosis-chip-container {
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  margin: -6px;
}

.diagnosis-chip {
  padding: 4px;
  margin: 2px 6px;
}
