.image-scroll::-webkit-scrollbar {
    height: 8px;
    max-width: 200px;
    background-color: #081624;
  }
  
  .image-scroll::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #081624;
    padding: 10px !important;
  }
  
  .image-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #0473b4;
  }
  
  .image-scroll::-webkit-scrollbar-thumb:hover {
    background-color: #0473b4;
  }  

  .loader .spinner-border {
    color: #fff;
    margin-left: 3px;
  }

@media (max-width: 1440px) {
    .image-container {
      min-width: calc(52% - 10px) !important; /* Show only 2 images on smaller screens */
    }
    .image-checkbox {
        right: 60px !important; /* Move the checkbox 30px from the right */
    }
}
