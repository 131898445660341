.projectSelector {
  position: relative;
  left: 0;
  top: 30px;
  right: 100px;
}
/* Add these styles at the end of your Selector.module.scss file */

.prompt {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #081624; /* Set the background color as needed */
  padding: 20px;
  border: 1px solid #ccc; /* Add a border for better visibility */
  z-index: 100001; /* Set a high z-index to ensure it appears above other elements */
  text-align: start;
  color: #fff;  
}

.prompt button {
  width: 70px;
  height: 38px;
  color: #fff;
  margin: 5px;
  padding: 5px;
  border-radius: 6px;
}

.prompts {
  display: flex !important;
  flex-direction: row-reverse;
  margin-top: 10px;
}

.prompts > button:nth-child(1) {
  background-color: red;
}

.prompts > button:nth-child(2) {
  background-color: green;
}
