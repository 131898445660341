.image-list-container {
  height: calc(
    100vh - 135px
  ); // 135px is the height of the header and the footer
  width: 100%;
  overflow-y: scroll;
  .selected-image {
    border: 2.5px solid #f0b93b;
    background-color: hsl(210, 57%, 50%, 0.2);
    border-radius: 8px;
  }
  .rejected-image {
    border: 2.5px solid #f0b93b;
    background-color: hsl(210, 57%, 50%, 0.2);
    border-radius: 8px;
  }
}

.image-list-item {
  margin: 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-bottom: 0.8px solid #0c2030;
  cursor: pointer;
  border-radius: 8px;
  position: relative; /* Add this to contain the absolute positioned icon */
}

.image-list-item-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 50;
  color: rgb(155, 20, 40); 
  padding: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-list-item:hover {
  background-color: #0c2030;
}

.image-list-item-image {
  width: 80px;
  height: 80px;
  margin-right: 20px;
  border-radius: 5px;
  object-fit: cover;
}

.image-list-item-title,
.image-list-item-date {
  margin-top: 10px;
  color: #f2f2f2;
  max-width: 120px;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  &,
  :hover {
    color: #f2f2f2;
  }
}

.image-list-container::-webkit-scrollbar-thumb {
  background-color: #081624;  /* Thumb color */
  border-radius: 10px;         /* Round corners of the scrollbar thumb */
}

/* Styles the part of the scrollbar track */
.image-list-container::-webkit-scrollbar-track {
  background: #0e2942;        /* Track color */
  border-radius: 10px;         /* Round corners of the scrollbar track */
}

/* Adjust the width of the scrollbar */
.image-list-container::-webkit-scrollbar {
  width: 8px;                 /* Width of the vertical scrollbar */
}

/* Change thumb color on hover */
.image-list-container::-webkit-scrollbar-thumb:hover {
  background-color: rgba(8, 22, 36, 0.8);     /* Darker color on hover */
}
