.projectSelector {
    position: fixed;
    display: flex;  // not ideal way yo style the component need to use cx since this is a child component.
    bottom: 180px;
    left: 100px;
    z-index: 10001; /* Adjust this value based on your layout */
    /* Styles the scrollbar itself (thumb) */
    ::-webkit-scrollbar-thumb {
      background-color: #0e2942;  /* Thumb color */
      border-radius: 10px;         /* Round corners of the scrollbar thumb */
    }
  
    /* Styles the part of the scrollbar track */
    ::-webkit-scrollbar-track {
      background: #081624;        /* Track color */
      border-radius: 10px;         /* Round corners of the scrollbar track */
    }
  
    /* Adjust the width of the scrollbar */
    ::-webkit-scrollbar {
      width: 8px;                 /* Width of the vertical scrollbar */
    }
  }
  
  .card {
    display: flex;
    justify-items: center;
    width: 400px; /* Adjust this value based on your layout */
    max-height: 380px;
    text-align: start;
    background-color: #081624 !important;
    opacity: 0.9;
    color: #fcfbfb;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 7px !important;
    overflow-y: auto !important;
  }
  
  .projectSelectorTitle {
    margin-top: 10px; /* Adjust this value based on your layout */
    margin-bottom: 20px !important;
    font-size: large;
    font-weight: bold;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    color: #fcfbfb !important;
    // color: #14304c;
  }
  
  .projectList {
    overflow: auto; /* Make the items scrollable */
  }
  
  .projectListItem {
    cursor: pointer;
    background-color: #081624 !important;
    transition: background-color 0.3s ease;
    border: 0.5px solid #14304c !important;
  
  
    &:hover {
      background-color: #0e2942 !important;
      border: 0.5px solid #f0b93b !important;
    }
  
    .itemTitle {
      font-size: medium;
      font-weight: normal;
      margin-bottom: 0px !important;
    }
  }
  