div.reset-password-container {
    width: 600px;
    height: 400px;
    margin: 0 auto; 
    border-radius: 8px; 
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000; /* Adjust this value based on your layout */
    background-color: #081624 !important;
    opacity: 0.9;
    color: #fcfbfb;
}

h5{
    margin: 25px !important;
    color: #fff;
}

div.reset-password-container input[type="password"] {
    width: auto;
    margin: 10px 20px; 
    background-color: white;
    color: #14304c;
    padding: 8px 12px; 
    border: none;
    border-radius: 4px;
}

.flex-btn {
    display: flex !important;
    justify-content: flex-end;
    gap: 5px !important;
}

.submit-btn {
    width: 100px;
    margin: 10px; 
    background-color: darkblue;
    color: white;
    padding: 10px; 
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s; 
}

.cancel-btn {
    background-color: darkred;
    width: 100px;
    margin: 10px 20px 10px 0; 
    color: white;
    padding: 10px; 
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s; 
}

.submit-btn:hover {
    background-color: blue; 
}

.cancel-btn:hover {
    background-color: red; 
}
